import { useContext } from 'react'
import { FlipperContext } from './FlipperProvider'
import { ExperimentService } from './flipper.service'
import { ExperimentInfo } from './flipper.types'

function checkIsControl(variant: ExperimentInfo | undefined, experimentId: string) {
  if (!variant) return true
  if (variant.extraData) return variant.extraData.variantName === 'control'

  const experiment = ExperimentService.experiments().find((experiment) => experiment.id === experimentId)

  if (!experiment) return true

  return experiment.names.control === variant.variantId
}

export const useExperiment = (experimentId: string) => {
  const { variants } = useContext(FlipperContext)

  const variant = variants[experimentId]

  const isControl = checkIsControl(variant, experimentId)

  return {
    variant,
    isControl,
    isExperimentActive: !!variant,
  } as const
}
